<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link> &gt;
                    <router-link :to="{ name: 'organization-search-product', params: { organizationId: this.$route.params.organizationId } }">Products</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="product">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption text-end grey--text text--darken-2 mb-0">Product {{ product.id }}</p>
                    <v-card tile elevation="4" class="pa-0 mt-0">
                        <v-toolbar dense flat color="blue darken-2" dark>
                            <v-toolbar-title>
                                Product
                                <span v-if="product.is_draft_pending && !product.published">(draft)</span>
                                <span v-if="product.is_draft_pending && product.published">(published)</span>
                                <v-chip v-if="product.is_active" label small class="px-1 ml-2 blue darken-2 white--text">Active</v-chip>
                                <!-- <TextLink :href="customerProductLink" target="_blank">Customer Link</TextLink> -->
                                <v-chip v-if="!product.is_active" label small class="px-1 ml-2 blue darken-2 black--text">Inactive</v-chip>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn text small class="px-1 ml-2 blue darken-2 white--text" @click="publishProduct" v-if="product.is_draft_pending && product.is_active">Update</v-btn>
                            <v-btn text small class="px-1 ml-2 blue darken-2 white--text" @click="publishProduct" v-if="product.is_draft_pending && !product.is_active">Publish</v-btn>
                            <v-btn icon color="white" @click="publishProduct" v-if="product.is_draft_pending">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" fixed-width/>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-8">Name</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="product.name" @input="saveProductName" dense/>
                            </p>

                            <p class="text-overline mb-0 mt-8">Alias</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="product.alias" @input="saveProductAlias" dense/>
                            </p>

                            <p class="text-overline mb-0 mt-8">Type</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelect :value="product.type" :items="productTypeChoices" @input="saveProductType" dense/>
                            </p>

                            <p class="text-overline mb-0 mt-8">Checkout Requirements</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelect label="Require name" :value="product.checkout.require_name" :items="yesnoChoices" @input="saveProductRequireName" dense/>
                            </p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelect label="Require email" :value="product.checkout.require_email" :items="yesnoChoices" @input="saveProductRequireEmail" dense/>
                            </p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelect label="Require signup" :value="product.checkout.require_signup" :items="yesnoChoices" @input="saveProductRequireSignup" dense/>
                            </p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelect label="Require billing" :value="product.checkout.require_billing" :items="yesnoChoices" @input="saveProductRequireBilling" dense/>
                            </p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelect label="Require shipping" :value="product.checkout.require_shipping" :items="yesnoChoices" @input="saveProductRequireShipping" dense/>
                            </p>
                            <!-- TODO: enter flat rate shipping (when using USPS) or set up custom rate shipping integration for FedEx, UPS, etc. based on package size and weight and the carrier... various services exist that help with the computation, or we can do our own query of the current rates and compute for the merchant... -->

                            <p class="text-overline mb-0 mt-8">Description</p>
                            <p class="text-caption mb-0 pb-0 mt-0">The description is PUBLIC and is shown to customers.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="product.description" @input="saveProductDescription" dense/>
                            </p>

                            <!-- TODO: move to comment component -->
                            <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                            <p class="text-caption mb-0 pb-0 mt-0">The comment is PRIVATE and is NOT shown to customers.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="product.comment" @input="saveProductComment" dense/>
                            </p> -->
                            <!-- <p class="mb-0 pb-0">
                                <TextLink :href="viewBrandProfileURL" target="_blank">{{ viewBrandProfileURL }}</TextLink>
                            </p> -->
                            <v-divider/>
                            <p class="text-overline mb-0 mt-8">
                                Pricing
                                <v-btn icon color="blue darken-2" @click="createPrice">
                                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                                </v-btn>
                            </p>
                            <v-list v-if="Array.isArray(productPriceList)">
                                <v-list-item v-for="item in productPriceList" :key="item.id">
                                    <v-list-item-content>
                                        <EditableProductPrice :value="item" :product="product" @input="saveProductPrice"/>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <!-- <v-card flat tile color="white">
                                <v-toolbar dense flat color="white">
                                    <v-toolbar-title></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon color="blue darken-2">
                                        <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                                    </v-btn>
                                </v-toolbar>
                            </v-card> -->
                        </v-card-text>
                    </v-card>
                    <!-- <v-dialog v-model="addPriceDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="blue lighten-3">
                            <v-toolbar-title >Add Price</v-toolbar-title>
                        </v-toolbar>
                        <v-form @submit.prevent="createPrice" @keyup.enter.native.prevent="createPrice" class="px-2">
                            <v-text-field v-model="newItemLabel" label="Label" hint="A label for this client token; for example which application or server is using it" ref="newItemLabelInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="blue white--text" @click="createPrice" :disabled="!isCreatePriceFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="addPriceDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';
import EditableTextSelect from '@/components/EditableTextSelect.vue';
import EditableProductPrice from '@/components/EditableProductPrice.vue';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        // TextLink,
        EditableText,
        EditableTextSelect,
        EditableProductPrice,
    },
    data: () => ({
        organization: null,
        product: null,
        productPriceList: null,
        status: null,
        error: null,

        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
        submitFormTimestamp: null,

        addPriceDialog: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            productTypeChoices: (state) => state.productTypeChoices,
            productPublishedChoices: (state) => state.productPublishedChoices,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        isCreatePriceFormComplete() {
            return false;
        },
        // customerProductLink() {
        //     // TODO: get default site with possible custom hostname...  and the brandprofile, if needd...
        //     return "";http://customer.etherlink-main.test/brand/libertydns/product?id=06J5B62A4Z7WRCCBET4G
        // },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableBrandProfileInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadProduct() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProduct: true });
                const query = { id: this.$route.query.id };
                if (this.$route.query.published) {
                    query.published = this.$route.query.published; // 'true' or 'false'
                }
                const response = await this.$client.organization(this.$route.params.organizationId).product.get(query);
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.product = response;
                    this.checkProduct();
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load product', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProduct: false });
            }
        },
        async checkProduct() {
            try {
                this.error = false;
                this.$store.commit('loading', { checkProduct: true });
                const query = { id: this.$route.query.id };
                const response = await this.$client.organization(this.$route.params.organizationId).product.check(query, { item: 'stripe' });
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.status = response;
                } else {
                    this.status = null;
                }
            } catch (err) {
                console.error('failed to check product status', err);
                this.status = null;
                this.error = true;
            } finally {
                this.$store.commit('loading', { checkProduct: false });
            }
        },
        async loadProductPriceList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProductPriceList: true });
                const query = { product_id: this.$route.query.id };
                if (this.$route.query.published === 'true') {
                    query.published = 'true'; // this.$route.query.published; // 'true' or 'false'
                } else {
                    query.published = 'false';
                }
                const response = await this.$client.organization(this.$route.params.organizationId).productPrice.search(query);
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.productPriceList = response.list;
                } else {
                    this.productPriceList = [];
                }
                // check if any of the prices have a draft pending, and enable the publish button for that
                const isPriceDraftPending = this.productPriceList.reduce((acc, cur) => acc || cur, false);
                if (isPriceDraftPending) {
                    this.$set(this.product, 'is_draft_pending', true);
                }
            } catch (err) {
                console.error('failed to load prices', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProductPriceList: false });
            }
        },
        async editBrandProfileAlias() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editBrandProfileAlias: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: 'brandprofile' }, { content: this.editableBrandProfileAlias });
                console.log(`editBrandProfileAlias: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditBrandProfile = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadSetting();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
                }
            } catch (err) {
                console.error('failed to edit BrandProfile', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
            } finally {
                this.$store.commit('loading', { editBrandProfileAlias: false });
            }
        },
        async saveProductAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveProductAttr: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.edit({ id: this.$route.query.id }, { [name]: value });
                console.log(`saveProductAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.product, name, value);
                    this.$set(this.product, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit product' });
                }
            } catch (err) {
                console.error(`failed to edit product attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit product' });
            } finally {
                this.$store.commit('loading', { saveProductAttr: false });
            }
        },
        async saveProductName(name) {
            this.saveProductAttr('name', name);
        },
        async saveProductAlias(alias) {
            this.saveProductAttr('alias', alias);
        },
        async saveProductType(type) {
            await this.saveProductAttr('type', type);
            await this.loadProductPriceList(); // server may have changed some prices to draft if they don't apply to the new product type
        },
        async saveProductRequireName(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_name = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductRequireEmail(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_email = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductRequireSignup(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_signup = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductRequireBilling(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_billing = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductRequireShipping(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_shipping = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductDescription(description) {
            this.saveProductAttr('description', description);
        },
        // TODO: redo this with new comment API and comment collection by ADDING a comment or EDITING an existing comment or DELETING an existing comment ; but these need to be done just once because comments can apply to any object; so we need a comment component that will show up on this view and user cna tap it to manage comments
        // async saveProductComment(comment) {
        //     this.saveProductAttr('comment', comment);
        // },
        async publishProduct() {
            try {
                this.error = false;
                this.$store.commit('loading', { publishProduct: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.publish({ id: this.$route.query.id });
                console.log(`publishProduct: response ${JSON.stringify(response)}`);
                if (typeof response?.isPublished === 'boolean') {
                    this.$set(this.product, 'is_active', response.isPublished);
                    if (response.isPublished) {
                        this.$set(this.product, 'is_draft_pending', false);
                        for (let i = 0; i < this.productPriceList.length; i += 1) {
                            const item = this.productPriceList[i];
                            item.is_active = response.isPublished;
                            item.is_draft_pending = false;
                            this.productPriceList.splice(i, 1, item);
                        }
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish product' });
                }
            } catch (err) {
                console.error('failed to publish product', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish product' });
            } finally {
                this.$store.commit('loading', { publishProduct: false });
            }
        },
        async unpublishProduct() {
            try {
                this.error = false;
                this.$store.commit('loading', { publishProduct: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.unpublish({ id: this.$route.query.id });
                console.log(`publishProduct: response ${JSON.stringify(response)}`);
                if (typeof response?.isPublished === 'boolean') {
                    this.$set(this.product, 'is_active', response.isPublished);
                    if (!response.isPublished) {
                        this.$set(this.product, 'is_draft_pending', true);
                    }
                    for (let i = 0; i < this.productPriceList.length; i += 1) {
                        const item = this.productPriceList[i];
                        item.is_active = false;
                        item.is_draft_pending = true;
                        this.productPriceList.splice(i, 1, item);
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish product' });
                }
            } catch (err) {
                console.error('failed to unpublish product', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish product' });
            } finally {
                this.$store.commit('loading', { publishProduct: false });
            }
        },
        async createPrice() {
            try {
                this.error = false;
                this.$store.commit('loading', { saveProductAttr: true });
                const response = await this.$client.organization(this.$route.params.organizationId).productPrice.create({
                    product_id: this.$route.query.id,
                });
                console.log(`saveProductAttr: response ${JSON.stringify(response)}`);
                if (response?.isCreated && response.item) {
                    const editedItem = { ...response.item, is_active: false, is_draft_pending: true };
                    this.productPriceList.push(editedItem);
                    this.$set(this.product, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create product price' });
                }
            } catch (err) {
                console.error('failed to edit product price', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create product price' });
            } finally {
                this.$store.commit('loading', { saveProductAttr: false });
            }
        },
        async saveProductPrice(item) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveProductPrice: true });
                const response = await this.$client.organization(this.$route.params.organizationId).productPrice.edit({ id: item.id }, item);
                console.log(`saveProductPrice: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    const editedItem = { ...item, is_draft_pending: true };
                    const idx = this.productPriceList.findIndex((storedItem) => storedItem.id === item.id);
                    if (idx > -1) {
                        this.productPriceList.splice(idx, 1, editedItem);
                    } else {
                        this.productPriceList.push(editedItem);
                    }
                    this.$set(this.product, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save product price' });
                }
            } catch (err) {
                console.error('failed to save product price', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to cresaveate product price' });
            } finally {
                this.$store.commit('loading', { saveProductPrice: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        this.loadProduct();
        this.loadProductPriceList();
    },
};
</script>
