<template>
    <v-card flat tile>
        <v-card-text>
            <p>Customer will be asked to enter an amount at checkout.</p>
            <v-checkbox v-model="showOptionalInputs" label="Set minimum and/or maximum amounts"/>
            <!-- <p>You can set optional minimum and maximum amounts.</p> -->
            <template v-if="showOptionalInputs">
            <v-text-field v-model="editableMinPrice" label="Minimum price (optional)" hint="Currency amount" dense="dense" ref="editableMinPriceInput" class="my-6" @change="submit"></v-text-field>
            <v-text-field v-model="editableMaxPrice" label="Maximum price (optional)" hint="Currency amount" dense="dense" ref="editableMaxPriceInput" class="my-6" @change="submit"></v-text-field>
            </template>

            <template v-if="Array.isArray(errors) && errors.length > 0">
                <p class="red--text" v-for="(error, idx) in errors" :key="idx" v-text="error.message"></p>
            </template>
        </v-card-text>
    </v-card>
</template>

<style scoped>

</style>

<script>
export default {
    props: ['value', 'errors'], // value must be a tiers array or null
    data: () => ({
        editMode: true,
        showOptionalInputs: false,
        editableMinPrice: null,
        editableMaxPrice: null,
        editableTiers: null, // a tiers array
        submitFormTimestamp: null,
    }),
    computed: {
    },
    watch: {
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.initView();
            }
        },
        editableTiers() {
            this.submit();
        },
    },
    methods: {
        initView() {
            if (Array.isArray(this.value)) {
                this.editableTiers = this.value;
            } else {
                this.editableTiers = [];
            }
            if (this.editableTiers.length > 1) {
                this.editableTiers.splice(1); // flat price requires a single tier, so delete all tiers after first one
            }
            if (this.editableTiers.length > 0) {
                const tier = this.editableTiers[0];
                const minPriceExp = Number.isInteger(tier.min_price_exp) ? tier.min_price_exp : -2;
                const minPrice = Number.isInteger(tier.min_price) ? tier.min_price : null;
                const minPriceDecimal = Number.isInteger(minPrice) ? minPrice * (10 ** minPriceExp) : null;
                const maxPriceExp = Number.isInteger(tier.max_price_exp) ? tier.max_price_exp : -2;
                const maxPrice = Number.isInteger(tier.max_price) ? tier.max_price : null;
                const maxPriceDecimal = Number.isInteger(maxPrice) ? maxPrice * (10 ** maxPriceExp) : null;
                this.editableMinPrice = minPriceDecimal?.toString(10); // may be null
                this.editableMaxPrice = maxPriceDecimal?.toString(10); // may be null
            } else {
                this.editableMinPrice = null;
                this.editableMaxPrice = null;
            }
            if (this.editableMinPrice || this.editableMaxPrice) {
                this.showOptionalInputs = true;
            }
            this.$nextTick(() => {
                setTimeout(() => { this.activate('editableMinPriceInput'); }, 1);
            });
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        submit() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            let minPrice = null;
            let minPriceExp = null;
            let maxPrice = null;
            let maxPriceExp = null;
            if (this.editableMinPrice?.length > 0) {
                minPrice = Math.round(this.editableMinPrice * 100);
                minPriceExp = -2;
            }
            if (this.editableMaxPrice?.length > 0) {
                maxPrice = Math.round(this.editableMaxPrice * 100);
                maxPriceExp = -2;
            }
            this.editableTiers = [
                {
                    min_price: minPrice,
                    min_price_exp: minPriceExp,
                    max_price: maxPrice,
                    max_price_exp: maxPriceExp,
                    max: 1,
                },
            ];
            const item = this.editableTiers;
            this.$emit('input', item);
            // this.cancel();
        },
        // cancel() {
        //     this.editMode = false;
        //     this.editableFlatPrice = null;
        //     this.editableTiers = null;
        // },
    },
    mounted() {
        this.initView();
    },
};
</script>
