<template>
    <v-card flat tile>
        <v-card-text>
            <p>Customer will be charged this amount at checkout for each unit. Customer will be able to edit quantity they want to buy. You can set a maximum of units they can buy in one transaction (optional).</p>

            <v-text-field v-model="editableUnitPrice" label="Unit price" hint="Currency amount" dense="dense" ref="editableUnitPriceInput" class="my-6" @change="submit"></v-text-field>

            <v-text-field v-model="editableUnitMax" label="Unit max" hint="Maximum number of units that can be purchased in one transaction" dense="dense" ref="editableUnitMaxInput" class="my-6" @change="submit"></v-text-field>

            <template v-if="Array.isArray(errors) && errors.length > 0">
                <p class="red--text" v-for="(error, idx) in errors" :key="idx" v-text="error.message"></p>
            </template>
        </v-card-text>
    </v-card>
</template>

<style scoped>

</style>

<script>
export default {
    props: ['value', 'errors'], // value must be a tiers array or null
    data: () => ({
        editMode: true,
        editableUnitPrice: null,
        editableUnitMax: null,
        editableTiers: null, // a tiers array
        submitFormTimestamp: null,
    }),
    computed: {
    },
    watch: {
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.initView();
            }
        },
    },
    methods: {
        initView() {
            if (Array.isArray(this.value)) {
                this.editableTiers = this.value;
            } else {
                this.editableTiers = [];
            }
            if (this.editableTiers.length > 1) {
                this.editableTiers.splice(1); // unit price requires a single tier, so delete all tiers after first one
            }
            if (this.editableTiers.length > 0) {
                const tier = this.editableTiers[0];
                const unitPriceExp = Number.isInteger(tier.unit_price_exp) ? tier.unit_price_exp : -2;
                const unitPrice = Number.isInteger(tier.unit_price) ? tier.unit_price : null;
                const unitPriceDecimal = Number.isInteger(unitPrice) ? unitPrice * (10 ** unitPriceExp) : null;
                this.editableUnitPrice = unitPriceDecimal?.toString(10); // may be null
                this.editableTierMax = tier.max?.toString(10); // may be null
            } else {
                this.editableUnitPrice = null;
                this.editableUnitMax = null;
            }
            this.$nextTick(() => {
                setTimeout(() => { this.activate('editableUnitPriceInput'); }, 1);
            });
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        submit() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            let max = null;
            if (this.editableUnitMax?.length > 0) {
                max = Number.parseInt(this.editableUnitMax, 10);
            }
            let unitPrice = null;
            let unitPriceExp = null;
            if (this.editableUnitPrice?.length > 0) {
                unitPrice = Math.round(this.editableUnitPrice * 100);
                unitPriceExp = -2;
            }
            this.editableTiers = [
                {
                    unit_price: unitPrice,
                    unit_price_exp: unitPriceExp,
                    max,
                },
            ];
            const item = this.editableTiers;
            this.$emit('input', item);
            // this.cancel();
        },
        // cancel() {
        //     this.editMode = false;
        //     this.editableUnitPrice = null;
        //     this.editableTiers = null;
        // },
    },
    mounted() {
        this.initView();
    },
};
</script>
