<template>
    <v-form @submit.prevent="submit" @keyup.enter.prevent="submit">
        <template v-if="!editMode">
            <v-list-item-title>
            <slot name="view">
                {{ value.alias }}

                <v-chip v-if="value.is_active" label x-small class="px-1 ml-2 blue darken-2 white--text">Active</v-chip>
                <v-chip v-if="!value.is_active" label x-small class="px-1 ml-2 amber black--text">Inactive</v-chip>
                <v-chip v-if="value.is_draft_pending" label x-small class="px-1 ml-2 amber darken-2 white--text">Draft</v-chip>
                <!-- <v-chip x-small color="blue darken-2" dark v-if="value.published">Active</v-chip>
                <v-chip x-small color="amber darken-2" dark v-if="!value.published">Draft</v-chip> -->
            </slot>
            <slot name="button">
                <v-btn icon color="blue darken-2" @click="editMode = true">
                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                </v-btn>
            </slot>
            </v-list-item-title>
        </template>
        <template v-if="editMode">
            <v-card flat class="ma-0 pa-0" color="grey lighten-4">
                <v-card-title class="mx-2 px-0 text-body-2">
                    Edit Price
                </v-card-title>
                <slot name="input">
                    <v-row no-gutters class="px-3">
                        <v-col cols="12">
                            <v-text-field v-model="editableAlias" label="Alias" hint="A way to refer to this price when creating links to checkout" dense="dense" ref="editableAliasInput" class="my-6"></v-text-field>

                            <v-select v-model="editableCurrency" label="Currency" dense :items="currencyChoices" class="my-6"></v-select>

                            <!-- <v-select v-model="editablePublished" label="Active" hint="Customers can only select published prices" dense :items="publishedChoices" class="my-6"></v-select> -->

                            <!-- TODO: move to comment component -->
                            <!-- <v-text-field v-model="editableComment" label="Comment" hint="The comment is PRIVATE and is NOT shown to customers" dense="dense" ref="editableCommentInput" class="my-6"></v-text-field> -->

                            <v-select v-model="editableBillingMethod" label="Bill computation" hint="How the product is computed and billed to customers" dense :items="billingMethodChoices" class="my-6"></v-select>

                            <template v-if="editableBillingMethod === 'custom'">
                                <EditableProductPriceCustom v-model="editableTiersCustom"/>
                                <!-- <pre>{{ JSON.stringify(editableTiersFlat, null, 2) }}</pre> -->
                            </template>
                            <template v-if="editableBillingMethod === 'flat'">
                                <EditableProductPriceFlat v-model="editableTiersFlat"/>
                                <!-- <pre>{{ JSON.stringify(editableTiersFlat, null, 2) }}</pre> -->
                            </template>
                            <template v-if="editableBillingMethod === 'unit'">
                                <EditableProductPriceUnit v-model="editableTiersUnit"/>
                                <!-- <pre>{{ JSON.stringify(editableTiersUnit, null, 2) }}</pre> -->
                            </template>
                            <template v-if="editableBillingMethod === 'package'">
                                <EditableProductPricePackage v-model="editableTiersPackage"/>
                                <!-- <pre>{{ JSON.stringify(editableTiersVolume, null, 2) }}</pre> -->
                            </template>
                            <template v-if="editableBillingMethod === 'volume'">
                                <EditableProductPriceVolume v-model="editableTiersVolume"/>
                                <!-- <pre>{{ JSON.stringify(editableTiersVolume, null, 2) }}</pre> -->
                            </template>
                            <template v-if="editableBillingMethod === 'stacked'">
                                <EditableProductPriceStacked v-model="editableTiersStacked"/>
                                <!-- <pre>{{ JSON.stringify(editableTiersStacked, null, 2) }}</pre> -->
                            </template>

                            <v-text-field v-model="editableUnitLabel" label="Unit label" hint="Identifies a unit of the product or a unit of usage on the bill" dense="dense" class="my-6"></v-text-field>

                            <v-checkbox v-model="editableRecurring" label="Recurring charge" dense class="my-6"></v-checkbox>
                            <!-- <v-select v-model="editableRecurring" label="Recurring" dense :items="recurringChoices" class="my-6"></v-select> -->

                            <template v-if="editableRecurring">
                                <v-row no-gutters align="center">
                                    <v-col cols="4">
                                        <p>Bill the customer every:</p>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="editableRecurringIntervalCount" label="How many" dense="dense" ref="editableRecurringIntervalCountInput" class="my-6"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select v-model="editableRecurringIntervalUnit" label="Duration" dense :items="recurringIntervalUnitChoices" class="my-6"></v-select>
                                    </v-col>
                                </v-row>
                                <v-select v-model="editableRecurringUsageType" label="Licensed or metered" dense :items="recurringUsageTypeChoices" class="mt-6"></v-select>
                                <p class="text-caption mb-6" v-if="editableRecurringUsageType === 'licensed'">
                                    Customer will be charged at regular intervals for the
                                    <span v-if="!priceBillingMethodProperties[editableBillingMethod].quantity">flat price</span>
                                    <span v-if="priceBillingMethodProperties[editableBillingMethod].quantity">quantity</span>
                                    indicated at time of purchase.
                                </p>
                                <p class="text-caption" v-if="editableRecurringUsageType === 'metered' && priceBillingMethodProperties[editableBillingMethod].metered">
                                    Customer will be charged at regular intervals for the actual usage incurred during the billing period.
                                </p>

                                <v-alert dense type="warning" border="left" elevation="2" class="mt-2" v-if="editableRecurringUsageType === 'metered' && !priceBillingMethodProperties[editableBillingMethod].metered">
                                    <template #prepend>
                                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="white--text"/>
                                    </template>
                                    <v-row align="center" class="ml-1">
                                        <v-col class="grow">
                                            Using selected billing method with metered usage means that usage data will be reported but will NOT affect the price.
                                        </v-col>
                                        <!-- TODO: link to registrar's site? or link to another page that we have with more info, that will then link to registrar's site? -->
                                        <!-- <v-col class="shrink">
                                            <v-btn :to="{ name: '?? dispute ??', params: { organizationId: this.$route.params.organizationId, domain: this.$route.params.domain } }">Dispute</v-btn>
                                        </v-col> -->
                                    </v-row>
                                </v-alert>

                                <v-select v-model="editableRecurringUsageMethod" label="Usage billing method" dense :items="recurringUsageMethodChoices" class="mt-12 mb-6" v-if="editableRecurringUsageType === 'metered'"></v-select>

                            </template>

                            <template v-if="Array.isArray(errors) && errors.length > 0">
                                <p class="red--text mt-3 mb-3" v-for="(error, idx) in errors" :key="idx">
                                    <span v-if="error.check === 'item' && Number.isInteger(error.index)">Tier {{ error.index + 1 }}. </span>
                                    {{ error.message }}
                                </p>
                            </template>
                        </v-col>
                    </v-row>
                </slot>
                <v-card-actions>
                    <slot name="submit">
                        <!-- <v-btn icon color="blue darken-2" @click="submit">
                            <font-awesome-icon :icon="['fas', 'check']"/>
                        </v-btn>
                        <v-btn icon color="grey darken-2" @click="cancel">
                            <font-awesome-icon :icon="['fas', 'times']"/>
                        </v-btn> -->
                        <v-btn color="blue darken-2 white--text" @click="submit" :disabled="!isPriceFormComplete">
                            Save
                        </v-btn>
                        <v-btn class="ml-2" color="grey darken-2" dark @click="cancel">
                            Cancel
                        </v-btn>
                    </slot>
                </v-card-actions>
            </v-card>
        </template>
    </v-form>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableProductPriceCustom from '@/components/EditableProductPriceCustom.vue';
import EditableProductPriceFlat from '@/components/EditableProductPriceFlat.vue';
import EditableProductPriceUnit from '@/components/EditableProductPriceUnit.vue';
import EditableProductPricePackage from '@/components/EditableProductPricePackage.vue';
import EditableProductPriceVolume from '@/components/EditableProductPriceVolume.vue';
import EditableProductPriceStacked from '@/components/EditableProductPriceStacked.vue';

export default {
    components: {
        EditableProductPriceCustom,
        EditableProductPriceFlat,
        EditableProductPriceUnit,
        EditableProductPricePackage,
        EditableProductPriceVolume,
        EditableProductPriceStacked,
    },
    props: ['value', 'product'/* , 'dense', 'label', 'hint' */], // value must be a product_price item (object), product is a product item (object) and it MUST have the product type defined
    data: () => ({
        editMode: false,
        editableAlias: null,
        editableCurrency: null,
        editablePublished: null,
        editableBillingMethod: null,
        editableUnitLabel: null,
        // editableComment: null, // TODO: move to comment component
        editableRecurring: null,
        editableRecurringIntervalCount: null,
        editableRecurringIntervalUnit: null,
        editableRecurringUsageType: null,
        editableRecurringUsageMethod: null,
        editableTiers: null, // a tiers array
        // separate tiers array for each billing method, so that if user is switching between
        // them we won't lose all the data -- if they switch from stacked to unit and back they
        // can continue to edit the stacked price as it was; however only the selected one will
        // be saved
        editableTiersCustom: null, // a tiers array
        editableTiersFlat: null, // a tiers array
        editableTiersUnit: null, // a tiers array
        editableTiersPackage: null, // a tiers array
        editableTiersVolume: null, // a tiers array
        editableTiersStacked: null, // a tiers array
        submitFormTimestamp: null,
        currencyChoices: [ // TODO: load this from server
            { text: 'USD', value: 'USD' },
        ],
        publishedChoices: [
            { text: 'Active', value: true },
            { text: 'Draft', value: false },
        ],
        // recurringChoices: [
        //     { text: 'One-time charge', value: false },
        //     { text: 'Recurring charge', value: true },
        // ],
        recurringIntervalUnitChoices: [
            { text: 'Day', value: 'day' },
            { text: 'Week', value: 'week' },
            { text: 'Month', value: 'month' },
            { text: 'Year', value: 'year' },
        ],
        recurringUsageTypeChoices: [
            { text: 'Licensed', value: 'licensed' },
            { text: 'Metered', value: 'metered' },
        ],
        recurringUsageMethodChoices: [
            { text: 'Maximum quantity used during billing period', value: 'max' },
            { text: 'Sum of all usage during billing period', value: 'sum' },
        ],
        errors: null,
    }),
    computed: {
        ...mapState({
            priceBillingMethodProperties: (state) => state.priceBillingMethodProperties,
        }),
        tiers() {
            switch (this.editableBillingMethod) {
            case 'custom':
                return this.editableTiersCustom;
            case 'flat':
                return this.editableTiersFlat;
            case 'unit':
                return this.editableTiersUnit;
            case 'package':
                return this.editableTiersPackage;
            case 'volume':
                return this.editableTiersVolume;
            case 'stacked':
                return this.editableTiersStacked;
            default:
                return null;
            }
        },
        isPriceFormComplete() {
            if (Array.isArray(this.errors) && this.errors.length > 0) {
                return false;
            }
            if (!Array.isArray(this.tiers) || this.tiers.length === 0) {
                return false;
            }
            return true;
        },
        billingMethodChoices() {
            let list;
            if (this.product?.type === 'donation') {
                list = [
                    { text: 'Flat', value: 'flat' },
                    { text: 'Custom', value: 'custom' },
                ];
            } else {
                list = [
                    { text: 'Flat', value: 'flat' },
                    { text: 'Per-Unit', value: 'unit' },
                    { text: 'Package', value: 'package' },
                    { text: 'Volume', value: 'volume' },
                    { text: 'Stacked', value: 'stacked' },
                ];
            }
            return list;
        },
    },
    watch: {
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.editableAlias = this.value.alias;
                this.editableCurrency = this.value.currency;
                this.editablePublished = this.value.published;
                // this.editableComment = this.value.comment; // TODO: move to comment component
                this.editableBillingMethod = this.value.billing_method;
                this.editableUnitLabel = this.value.unit_label;
                if (this.editableBillingMethod === 'custom') {
                    this.editableTiersCustom = this.value.tiers;
                }
                if (this.editableBillingMethod === 'flat') {
                    this.editableTiersFlat = this.value.tiers;
                }
                if (this.editableBillingMethod === 'unit') {
                    this.editableTiersUnit = this.value.tiers;
                }
                if (this.editableBillingMethod === 'package') {
                    this.editableTiersPackage = this.value.tiers;
                }
                if (this.editableBillingMethod === 'volume') {
                    this.editableTiersVolume = this.value.tiers;
                }
                if (this.editableBillingMethod === 'stacked') {
                    this.editableTiersStacked = this.value.tiers;
                }
                this.editableRecurring = this.value.recurring;
                this.editableRecurringIntervalCount = this.value.recurring_interval_count;
                this.editableRecurringIntervalUnit = this.value.recurring_interval_unit;
                this.editableRecurringUsageType = this.value.recurring_usage_type;
                this.editableRecurringUsageMethod = this.value.recurring_usage_method;
                // TODO: what to do about tiers? it's for display only (and that should also be handled in subscomponent that does editing)
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableAliasInput'); }, 1);
                });
            }
        },
        editableBillingMethod() {
            this.errors = [];
        },
        editableRecurring(newValue, oldValue) {
            if (newValue && !oldValue) {
                // set some reasonable defaults for recurring charges
                this.editableRecurringIntervalCount ??= 1;
                this.editableRecurringIntervalUnit ??= 'month';
                this.editableRecurringUsageType ??= 'licensed';
                this.editableRecurringUsageMethod ??= null; // applies only to metered
            }
        },
        editableRecurringUsageType(newValue) {
            if (newValue === 'licensed') {
                this.editableRecurringUsageMethod = null;
            }
            if (newValue === 'metered') {
                this.editableRecurringUsageMethod = 'sum'; // reasonable default, user can change it
            }
        },
        editableTiersCustom(newValue) {
            this.checkCustomTiers(newValue);
        },
        editableTiersFlat(newValue) {
            this.checkFlatTiers(newValue);
        },
        editableTiersUnit(newValue) {
            this.checkUnitTiers(newValue);
        },
        editableTiersPackage(newValue) {
            this.checkPackageTiers(newValue);
        },
        editableTiersVolume(newValue) {
            this.checkVolumeTiers(newValue);
        },
        editableTiersStacked(newValue) {
            this.checkStackedTiers(newValue);
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        submit() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            let recurringIntervalCount = null;
            if (typeof this.editableRecurringIntervalCount === 'number') {
                recurringIntervalCount = this.editableRecurringIntervalCount;
            } else if (typeof this.editableRecurringIntervalCount === 'string') {
                recurringIntervalCount = Number.parseInt(this.editableRecurringIntervalCount, 10);
            }
            const item = {
                ...this.value,
                alias: this.editableAlias,
                currency: this.editableCurrency,
                published: this.editablePublished,
                // comment: this.editableComment, // TODO: move to comment component
                billing_method: this.editableBillingMethod,
                tiers: this.tiers,
                recurring: this.editableRecurring ?? false,
                recurring_interval_count: recurringIntervalCount,
                recurring_interval_unit: this.editableRecurringIntervalUnit,
                recurring_usage_type: this.editableRecurringUsageType,
                recurring_usage_method: this.editableRecurringUsageMethod,
                unit_label: this.editableUnitLabel,
            };
            this.$emit('input', item);
            this.cancel();
        },
        cancel() {
            this.editMode = false;
            this.editableAlias = null;
            this.editableCurrency = null;
            this.editablePublished = null;
            // this.editableComment = null; // TODO: move to comment component
            this.editableBillingMethod = null;
            this.editableTiersCustom = null;
            this.editableTiersFlat = null;
            this.editableTiersUnit = null;
            this.editableTiersPackage = null;
            this.editableTiersVolume = null;
            this.editableTiersStacked = null;
            this.editableRecurring = null;
            this.editableRecurringIntervalCount = null;
            this.editableRecurringIntervalUnit = null;
            this.editableRecurringUsageType = null;
            this.editableRecurringUsageMethod = null;
            this.editableUnitLabel = null;
        },
        /**
         * Check package, volume, and stacked tiers.
         * Return null if input is valid, or an array of fault descriptors.
         */
        checkTiers(tiers) {
            /* eslint-disable object-curly-newline */
            if (!Array.isArray(tiers) || tiers.length === 0) {
                return [{ check: 'list', fault: 'empty', message: 'At least one tier is required' }];
            }
            const faults = [];
            // each tier should have flat fee, unit price, or both
            for (let i = 0; i < tiers.length; i += 1) {
                const tier = tiers[i];
                if (Number.isInteger(tier.unit_price) && (tier.unit_price < 0 || !Number.isInteger(tier.unit_price_exp))) {
                    faults.push({ check: 'item', index: i, fault: 'invalid', attr: 'unit_price', message: 'Unit price must be a non-negative currency amount' });
                }
                if (Number.isInteger(tier.flat_price) && (tier.flat_price < 0 || !Number.isInteger(tier.flat_price_exp))) {
                    faults.push({ check: 'item', index: i, fault: 'invalid', attr: 'flat_price', message: 'Flat price must be a non-negative currency amount' });
                }
                if (!Number.isInteger(tier.flat_price) && !Number.isInteger(tier.unit_price)) {
                    faults.push({ check: 'item', index: i, fault: 'empty', message: 'At least one of flat price or unit price is required' });
                }
            }
            return faults;
        },
        checkTiersPackage(tiers) {
            const faults = [];
            // the max value must be present in every tier
            // and it must increase in every tier where it's defined
            // where max is set it must be a non-negative number
            let max = null;
            for (let i = 0; i < tiers.length; i += 1) {
                const tier = tiers[i];
                if (!Number.isInteger(tier.max) || tier.max < 0) {
                    faults.push({ check: 'item', index: i, fault: 'invalid', attr: 'max', message: 'Quantity must be a non-negative integer' });
                }
                if (Number.isInteger(max) && tier.max <= max) {
                    faults.push({ check: 'item', index: i, fault: 'invalid', attr: 'max', message: 'Quantity in each tier must be higher than previous tier' });
                }
                max = tier.max;
            }
            return faults;
        },
        checkTiersVolumeStacked(tiers) {
            const faults = [];
            // the max value must be present in every tier except the last one where it's optional
            // and it must increase in every tier where it's defined
            // where max is set it must be a non-negative number
            let max = null;
            for (let i = 0; i < tiers.length - 1; i += 1) {
                const tier = tiers[i];
                if (!Number.isInteger(tier.max) || tier.max < 0) {
                    faults.push({ check: 'item', index: i, fault: 'invalid', attr: 'max', message: 'Max must be a non-negative integer' });
                }
                if (Number.isInteger(max) && tier.max <= max) {
                    faults.push({ check: 'item', index: i, fault: 'invalid', attr: 'max', message: 'Max of each tier must be higher than previous tier' });
                }
                max = tier.max;
            }
            // max is optional in the last tier, but if present it must be greater than prior max
            const lastTier = tiers.at(-1);
            if (!Number.isInteger(lastTier.max) && lastTier.max !== null && lastTier.max !== '') {
                faults.push({ check: 'item', index: tiers.length - 1, fault: 'invalid', attr: 'max', message: 'Max of last tier must be empty or a non-negative integer' });
            }
            if (Number.isInteger(lastTier.max) && lastTier.max <= max) {
                faults.push({ check: 'item', index: tiers.length - 1, fault: 'invalid', attr: 'max', message: 'Max of last tier must be empty or higher than previous tier' });
            }
            return faults;
        },
        checkCustomTiers(tiers) {
            this.errors = [];
            /* eslint-disable object-curly-newline */
            if (!Array.isArray(tiers) || tiers.length !== 1) {
                this.errors.push({ check: 'list', fault: 'empty', message: 'Exactly one tier is required' });
                return;
            }
            const tier = tiers[0];
            // min and max are optional for custom pricing, but if provided they must be non-negative currency amounts
            if (!Number.isInteger(tier.min_price) && tier.min_price !== null && tier.min_price !== '') {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'min_price', message: 'Minimum price must be empty or a non-negative currency amount' });
            }
            if (Number.isInteger(tier.min_price) && (tier.min_price < 0 || !Number.isInteger(tier.min_price_exp))) {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'min_price', message: 'Minimum price must be empty or a non-negative currency amount' });
            }
            if (!Number.isInteger(tier.max_price) && tier.max_price !== null && tier.max_price !== '') {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'max_price', message: 'Maximum price must be empty or a non-negative currency amount' });
            }
            if (Number.isInteger(tier.max_price) && (tier.max_price < 0 || !Number.isInteger(tier.max_price_exp))) {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'max_price', message: 'Maximum price must be empty or a non-negative currency amount' });
            }
            if (!Number.isInteger(tier.max) || tier.max !== 1) {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'max', message: 'Max must be set to 1' });
            }
        },
        checkFlatTiers(tiers) {
            this.errors = [];
            /* eslint-disable object-curly-newline */
            if (!Array.isArray(tiers) || tiers.length !== 1) {
                this.errors.push({ check: 'list', fault: 'empty', message: 'Exactly one tier is required' });
                return;
            }
            const tier = tiers[0];
            if (!Number.isInteger(tier.flat_price)) {
                this.errors.push({ check: 'item', index: 0, fault: 'empty', attr: 'flat_price', message: 'Flat price is required' });
            }
            if (Number.isInteger(tier.flat_price) && (tier.flat_price < 0 || !Number.isInteger(tier.flat_price_exp))) {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'flat_price', message: 'Flat price must be a non-negative currency amount' });
            }
            if (!Number.isInteger(tier.max) || tier.max !== 1) {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'max', message: 'Max must be set to 1' });
            }
        },
        checkUnitTiers(tiers) {
            this.errors = [];
            /* eslint-disable object-curly-newline */
            if (!Array.isArray(tiers) || tiers.length !== 1) {
                this.errors.push({ check: 'list', fault: 'empty', message: 'Exactly one tier is required' });
                // return;
            }
            const tier = tiers[0];
            if (!Number.isInteger(tier.unit_price)) {
                this.errors.push({ check: 'item', index: 0, fault: 'empty', attr: 'unit_price', message: 'Unit price is required' });
            }
            if (Number.isInteger(tier.unit_price) && (tier.unit_price < 0 || !Number.isInteger(tier.unit_price_exp))) {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'unit_price', message: 'Unit price must be a non-negative currency amount' });
            }
            if (!Number.isInteger(tier.max) && tier.max !== null && tier.max !== '') {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'max', message: 'Max must be empty or a non-negative integer' });
            }
            if (Number.isInteger(tier.max) && tier.max < 0) {
                this.errors.push({ check: 'item', index: 0, fault: 'invalid', attr: 'max', message: 'Max must be empty or a non-negative integer' });
            }
        },
        checkPackageTiers(tiers) {
            this.errors = this.checkTiers(tiers);
            if (this.errors.length === 0) {
                this.errors = this.checkTiersPackage(tiers);
            }
        },
        checkVolumeTiers(tiers) {
            this.errors = this.checkTiers(tiers);
            if (this.errors.length === 0) {
                this.errors = this.checkTiersVolumeStacked(tiers);
            }
        },
        checkStackedTiers(tiers) {
            this.errors = this.checkTiers(tiers);
            if (this.errors.length === 0) {
                this.errors = this.checkTiersVolumeStacked(tiers);
            }
        },
    },
};
</script>
